"use client"
import { getTheme } from "@/theme"
import { Box, Fade } from "@mui/material"

export const AnimationThree = () => {
    const theme = getTheme()

    return (
        <Fade in timeout={1300}>
            <Box
                sx={{
                    position: "relative",
                    width: { xs: "100%", lg: "70%", aspectRatio: "2 / 1" },
                }}
            >
                <Box
                    component="img"
                    sx={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: -1 }}
                    alt="Background stars"
                    src="/images/hero/background-stars.webp"
                />
                <Box
                    component="img"
                    width="100%"
                    alt="Content being reviewed in the Recall knowledge base"
                    src="/images/hero/review.webp"
                />
                <Box
                    component="svg"
                    sx={{
                        position: "absolute",
                        overflow: "visible !important",
                        top: 0,
                        left: 0,
                        width: "100%",
                        zIndex: -1,
                    }}
                    className="review-ellipse"
                    viewBox="0 0 1550 789"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_f_250_1029)">
                        <ellipse
                            cx="512.499"
                            cy="386"
                            rx="267.5"
                            ry="141"
                            transform="rotate(180 512.499 386)"
                            fill={theme.palette.primary.main}
                            fillOpacity="0.3"
                        />
                    </g>
                    <g filter="url(#filter1_f_250_1029)">
                        <ellipse
                            cx="1037.5"
                            cy="403"
                            rx="267.5"
                            ry="141"
                            transform="rotate(180 1037.5 403)"
                            fill={theme.palette.primary.main}
                            fillOpacity="0.3"
                        />
                    </g>
                    <g filter="url(#filter2_f_250_1029)">
                        <ellipse
                            cx="847.669"
                            cy="404.215"
                            rx="466.669"
                            ry="175.215"
                            transform="rotate(180 847.669 404.215)"
                            fill={theme.palette.primary.main}
                            fillOpacity="0.14"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_f_250_1029"
                            x="0.986816"
                            y="0.987793"
                            width="1023.02"
                            height="770.024"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                            />
                            <feGaussianBlur
                                stdDeviation="122.006"
                                result="effect1_foregroundBlur_250_1029"
                            />
                        </filter>
                        <filter
                            id="filter1_f_250_1029"
                            x="525.988"
                            y="17.9878"
                            width="1023.02"
                            height="770.024"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                            />
                            <feGaussianBlur
                                stdDeviation="122.006"
                                result="effect1_foregroundBlur_250_1029"
                            />
                        </filter>
                        <filter
                            id="filter2_f_250_1029"
                            x="276.422"
                            y="124.423"
                            width="1142.49"
                            height="559.584"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                            />
                            <feGaussianBlur
                                stdDeviation="52.2883"
                                result="effect1_foregroundBlur_250_1029"
                            />
                        </filter>
                    </defs>
                </Box>
            </Box>
        </Fade>
    )
}
