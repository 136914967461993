"use client"

import { Box, SxProps, Theme, alpha } from "@mui/material"
import Image from "next/image"
import { Highlight } from "../Highlight"
import { Section } from "../layout/Section"
import Card from "../surfaces/Card"
import { Title, TitleSecondary, TitleTertiary } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { getTheme } from "@/theme"

export default function BuildExample() {
    const theme = getTheme()

    return (
        <Section>
            <SlideUpAnimation>
                <Title component="h2">
                    <Highlight>Build your knowledge</Highlight>
                </Title>
            </SlideUpAnimation>

            <Box sx={styles.content}>
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: "-40%",
                        opacity: 0.7,
                        display: { xs: "none", md: "block" },
                    }}
                >
                    <Image width={600} height={600} src="/svgs/planet.svg" alt="Planet image" />
                </Box>
                <Box display="flex" flexDirection="column" gap={{ xs: 1, md: 2 }} flex={1}>
                    <SlideUpAnimation delay="0.4s">
                        <TitleSecondary component="h3" textAlign="left">
                            A new approach to categorization
                        </TitleSecondary>
                    </SlideUpAnimation>
                    <SlideUpAnimation delay="0.6s">
                        <TitleTertiary component="h4" textAlign="left">
                            Your saved content is automatically categorized based on what it
                            mentions providing an intuitive way of organizing your content and
                            making it effortless to find again when its needed.
                        </TitleTertiary>
                    </SlideUpAnimation>
                </Box>
                <Box flex={1}>
                    <SlideUpAnimation delay="0.2s">
                        <Card
                            sx={{ pb: 0, p: 2 }}
                            glow={{
                                position: "10% 50%",
                                color: alpha(theme.palette.primary.main, 0.15),
                            }}
                        >
                            <Box
                                width="100%"
                                component="img"
                                alt="Categorize content in your knowledge base"
                                src="images/categorize-content.webp"
                            />
                        </Card>
                    </SlideUpAnimation>
                </Box>
            </Box>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    content: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        textAlign: "center",
        my: { xs: 2, md: 6 },
        gap: { xs: 2, md: 4 },
    },
}
