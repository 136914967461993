"use client"

import { ROUTES } from "@/constants/routes"
import { Grid, SxProps, Theme, Typography } from "@mui/material"
import Image from "next/image"
import { InlineLink } from "../links/InlineLink"
import { Section } from "../layout/Section"
import Card from "../surfaces/Card"
import { Title } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"

const features = [
    {
        title: "Summarize any online content",
        image: "/images/features-online-content.png",
        description: (
            <>
                Get to the point faster with key point summaries of podcasts,{" "}
                <InlineLink
                    color="white"
                    href={ROUTES.BLOGS.YOUTUBE_VIDE_SUMMARIZER}
                    target="_blank"
                >
                    YouTube videos
                </InlineLink>
                , news articles,{" "}
                <InlineLink color="white" href={ROUTES.BLOGS.PDF_SUMMARIZER} target="_blank">
                    PDFs
                </InlineLink>{" "}
                and more.
            </>
        ),
    },
    {
        title: "Automatic connections",
        image: "/images/features-auto-connections.png",
        description:
            "New content that you save is automatically linked with existing content helping resurface related information.",
    },
    {
        title: "Automatic Categorization",
        image: "/images/features-auto-categorization.png",
        description:
            "Manual categorization is tedious and leads to inconsistencies. Recall uses AI to categorize your saved content.",
    },
    {
        title: "Spaced Repetition",
        image: "/images/features-spaced-repetition.png",
        description: (
            <>
                Learning is an integral part of our lives. Recall makes it easier with{" "}
                <InlineLink color="white" href={ROUTES.BLOGS.SPACED_REPETITION} target="_blank">
                    Spaced Repetition
                </InlineLink>
                .
            </>
        ),
    },
    {
        title: "Data Export",
        image: "/images/features-data-export.png",
        description: "Export your notes to markdown for simple interoperability.",
    },
    {
        title: "Offline-first",
        image: "/images/features-offline-first.png",
        description: "All your data is stored locally on your device so you can access it offline.",
    },
]

export default function Features() {
    return (
        <Section>
            <SlideUpAnimation>
                <Title component="h2" mb={{ xs: 2, lg: 6 }}>
                    Everything you need in one application
                </Title>
            </SlideUpAnimation>
            <Grid container alignItems="stretch" justifyContent="center" spacing={2}>
                {features.map((feature, index: number) => (
                    <Grid key={feature.title} container item md={6} lg={4}>
                        <SlideUpAnimation delay={`${0.1 * index}s`}>
                            <Card sx={{ minHeight: 280 }}>
                                <Image
                                    src={feature.image}
                                    width={50}
                                    height={50}
                                    alt={feature.title}
                                />
                                <Typography
                                    my={2}
                                    sx={{ fontSize: { xs: 20, md: 24 } }}
                                    fontWeight={600}
                                >
                                    {feature.title}
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    {feature.description}
                                </Typography>
                            </Card>
                        </SlideUpAnimation>
                    </Grid>
                ))}
            </Grid>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {}
