"use client"

import { ROUTES } from "@/constants/routes"
import { Grid, Typography, alpha } from "@mui/material"
import Image from "next/image"
import { Highlight } from "../Highlight"
import { Section } from "../layout/Section"
import { InlineLink } from "../links/InlineLink"
import Card from "../surfaces/Card"
import { Title, TitleSecondary } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { getTheme } from "@/theme"

const statements = [
    {
        title: "Data ownership",
        image: "/svgs/data-ownership.svg",
        description:
            "Your data is yours and you have full control over it. We do not use your data for any other purpose than providing a service to you.",
    },
    {
        title: "Data portability",
        image: "/svgs/data-portability.svg",
        description:
            "You can export all your data anytime in Markdown format, putting the power of portability and accessibility directly in your hands.",
    },
    {
        title: "Servers in the EU",
        image: "/svgs/eu-data.svg",
        description:
            "The primary location of your data is on your device although it is backed up on our servers hosted using Google Cloud in the EU.",
    },
]

export default function PrivacyStatement() {
    const theme = getTheme()

    return (
        <Section>
            <SlideUpAnimation>
                <Title component="h2">
                    <Highlight>Security and data protection</Highlight>
                </Title>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.2s">
                <TitleSecondary component="h3" mt={2} mb={3}>
                    Recall is built using best practices and with a strong commitment to security
                    and data protection.
                </TitleSecondary>
            </SlideUpAnimation>
            <Grid container alignItems="stretch" justifyContent="center" spacing={2}>
                {statements.map((statement, index: number) => (
                    <Grid key={statement.title} container item md={6} lg={4}>
                        <SlideUpAnimation delay={`${0.1 * index}s`}>
                            <Card
                                sx={{ minHeight: 280, px: 3 }}
                                glow={{
                                    position: "50% 50%",
                                    color: alpha(theme.palette.primary.main, 0.15),
                                }}
                            >
                                <Image
                                    src={statement.image}
                                    width={80}
                                    height={80}
                                    alt={statement.title}
                                />
                                <Typography
                                    my={1}
                                    sx={{ fontSize: { xs: 20, md: 24 } }}
                                    fontWeight={600}
                                    color="text.secondary"
                                >
                                    {statement.title}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ color: "rgba(255, 255, 255, 0.6)" }}
                                    textAlign="left"
                                    fontWeight={500}
                                >
                                    {statement.description}
                                </Typography>
                            </Card>
                        </SlideUpAnimation>
                    </Grid>
                ))}
                <SlideUpAnimation delay="0.4s">
                    <Typography mt={3} p={1}>
                        Read more about how your data is handled in our{" "}
                        <InlineLink target="_blank" href={ROUTES.PRIVACY_POLICY}>
                            privacy policy
                        </InlineLink>
                        .
                    </Typography>
                </SlideUpAnimation>
            </Grid>
        </Section>
    )
}
