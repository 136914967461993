import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/AsSeenIn.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/BuildExample.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/ConnectionsExample.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/CTA.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/Demo.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/Features.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/Hero.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/Platforms.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/PrivacyStatement.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/SummaryExample.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/src/app/components/home/Testimonials.tsx")