"use client"

import { getTheme } from "@/theme"
import { Box, Button, SxProps, Theme, alpha } from "@mui/material"

interface Props {
    actions: string[]
    active: string
    setActive: Function
    handleHover?: (_: string) => void
}

export const ButtonGroup = ({ actions, active, setActive, handleHover = () => {} }: Props) => {
    return (
        <Box sx={styles.buttonGroup}>
            {actions.map((action) => (
                <Button
                    onMouseOver={() => handleHover(action)}
                    sx={{
                        ...styles.button,
                        background:
                            active === action ? alpha(theme.palette.common.white, 0.1) : "none",
                    }}
                    onClick={() => setActive(action)}
                    key={action}
                >
                    {action}
                </Button>
            ))}
        </Box>
    )
}

const theme = getTheme()

const styles: Record<string, SxProps<Theme>> = {
    buttonGroup: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 1,
        background: alpha(theme.palette.common.white, 0.05),
        borderRadius: "30px",
        p: 1,
        zIndex: 10000,
    },
    button: {
        borderRadius: 4,
        color: "white",
        fontSize: theme.typography.subtitle1,
        width: 132,
        fontWeight: 500,
        ":hover": {
            background: alpha(theme.palette.common.white, 0.1),
        },

        [theme.breakpoints.down("md")]: {
            width: "auto",
            fontSize: theme.typography.subtitle2.fontSize,
        },
    },
}
