"use client"
import { Box, SxProps, Theme } from "@mui/material"
import { memo, useState } from "react"
import { ButtonGroup } from "../buttons/ButtonGroup"
import { AnimationOne } from "./AnimationOne"
import { AnimationThree } from "./AnimationThree"
import { AnimationTwo } from "./AnimationTwo"

const actions = ["Summarize", "Connect", "Review"]

export const HeroAnimationComponent = () => {
    const [active, setActive] = useState(actions[0])

    return (
        <Box sx={styles.container}>
            <ButtonGroup actions={actions} active={active} setActive={setActive} />
            {active === "Summarize" && <AnimationOne />}
            {active === "Connect" && <AnimationTwo />}
            {active === "Review" && <AnimationThree />}
        </Box>
    )
}

export const HeroAnimation = memo(HeroAnimationComponent)

const styles: Record<string, SxProps<Theme>> = {
    container: {
        boxShadow: "0px 1px 1px 0px rgba(255, 255, 255, 0.10) inset",
        background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.02) 100%)",
        borderRadius: 2,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        pt: 2,
        pb: 4,
        width: { xs: "100%", md: "90%" },
        gap: 3,
    },
}
