"use client"

import { Box, Container, SxProps, Theme } from "@mui/material"
import { Title } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { Highlight } from "../Highlight"
import { Section } from "../layout/Section"

export default function Demo() {
    return (
        <Section>
            <SlideUpAnimation>
                <Title component="h2" sx={{ pt: 0 }} mb={{ xs: 2, lg: 4 }}>
                    <Highlight>Recall in 2 minutes</Highlight>
                </Title>
            </SlideUpAnimation>
            <Box
                sx={styles.video}
                component="video"
                controls
                poster="images/recall-demo-in-2minutes.webp"
            >
                <source src="https://firebasestorage.googleapis.com/v0/b/recall-public/o/static_assets%2Fvideo%2Frecall-demo-042024.mp4?alt=media&token=6b15145b-1e9d-472e-a711-8db01a4c4757" />
                Your browser does not support the video tag.
            </Box>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    video: {
        width: { xs: "100%", lg: "80%" },
        height: "100%",
        borderRadius: 1,
        border: "1px solid #ffffff20",
    },
}
