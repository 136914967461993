"use client"
import { Box, SxProps, Theme, alpha } from "@mui/material"
import { Highlight } from "../Highlight"
import { Section } from "../layout/Section"
import Card from "../surfaces/Card"
import { Title, TitleSecondary, TitleTertiary } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { getTheme } from "@/theme"

export default function ConnectionsExample() {
    const theme = getTheme()

    return (
        <Section>
            <SlideUpAnimation>
                <Title component="h2">
                    <Highlight>Discover connections</Highlight>
                </Title>
            </SlideUpAnimation>
            <Box sx={styles.content}>
                <Box display="flex" flexDirection="column" gap={2} flex={1}>
                    <SlideUpAnimation delay="0.4s">
                        <TitleSecondary component="h3" textAlign="left">
                            The world is dynamic and interconnected, so too should be your knowledge
                            base
                        </TitleSecondary>
                    </SlideUpAnimation>
                    <SlideUpAnimation delay="0.6s">
                        <TitleTertiary component="h4" textAlign="left">
                            Recall saves your content in a knowledge graph helping you find
                            connections and resurface past content when it is most relevant. <br />
                            This helps you understand complex topics and might even spark new ideas
                            where you don&apos;t expect them.
                        </TitleTertiary>
                    </SlideUpAnimation>
                </Box>
                <Box flex={1}>
                    <SlideUpAnimation delay="0.2s">
                        <Card
                            sx={{
                                pb: 0,
                                p: 2,
                            }}
                            glow={{
                                position: "50% 50%",
                                color: alpha(theme.palette.secondary.main, 0.15),
                            }}
                        >
                            <Box
                                width="100%"
                                component="img"
                                alt="Discover connections in the content you consume"
                                src="images/connect-content.webp"
                            />
                        </Card>
                    </SlideUpAnimation>
                </Box>
            </Box>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        textAlign: "center",
        my: { xs: 2, md: 6 },
        gap: { xs: 2, md: 4 },
    },
}
