"use client"

import { getTheme } from "@/theme"
import { useMediaQuery } from "@mui/material"
import Image from "next/image"

const theme = getTheme()
export const HeroArrow = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const scale = isMobile ? 0.4 : 0.8

    return (
        <Image
            src="/images/hero/down-arrow.webp"
            alt="Arrow down"
            width={138 * scale}
            height={226 * scale}
            priority
        />
    )
}
