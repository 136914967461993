import { Box, SxProps, Theme } from "@mui/material"
import Image from "next/image"

export const Ellipse = ({ index, sx }: { index: number; sx: SxProps<Theme> }) => {
    return (
        <Box sx={sx}>
            <Image
                quality={100}
                alt="Content being summarized and added to the Recall knowledge base"
                className={`ellipse-${index}`}
                src="/svgs/ellipse.svg"
                fill
                style={{ willChange: "transform" }}
            />
        </Box>
    )
}
