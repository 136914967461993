"use client"
import { ROUTES } from "@/constants/routes"
import { Box, Container, SxProps, Theme } from "@mui/material"
import { ActionButton } from "../buttons/ActionButton"
import { Title, TitleSecondary } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { HeroAnimation } from "./HeroAnimation"
import { HeroArrow } from "./HeroArrow"
import { Glow } from "../Glow"

export default function Hero() {
    return (
        <Container component="section" sx={styles.section}>
            <SlideUpAnimation visible delay="0s">
                <Title>Summarize Anything, Forget Nothing</Title>
            </SlideUpAnimation>
            <SlideUpAnimation visible delay="0s">
                <TitleSecondary pb={3}>
                    Summarize any online content and save it to your knowledge base where it&apos;s
                    automatically organized and interlinked for easy rediscovery
                </TitleSecondary>
            </SlideUpAnimation>
            <SlideUpAnimation visible delay="0.1s">
                <Glow sx={{ transform: "translateY(-50%)" }} />
                <ActionButton sx={{ mb: 4 }} href={ROUTES.SIGNUP} aria-label="Signup link button">
                    Get started for free
                </ActionButton>
            </SlideUpAnimation>
            <SlideUpAnimation visible delay="0.2s">
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <HeroAnimation />
                </Box>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.3s">
                <HeroArrow />
            </SlideUpAnimation>
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    section: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        mt: 2,
        position: "relative",
    },
}
