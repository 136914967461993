"use client"
import { Box, Container, SxProps, Theme } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { Highlight } from "../Highlight"
import { ButtonGroup } from "../buttons/ButtonGroup"
import Card from "../surfaces/Card"
import { Title, TitleSecondary, TitleTertiary } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"

const videoUrl = {
    Podcasts: "videos/website_podcast.mp4",
    PDFs: "videos/website_pdf.mp4",
    YouTube: "videos/website_youtube.mp4",
    News: "videos/website_news.mp4",
    Blogs: "videos/website_blog.mp4",
    Recipes: "videos/website_recipe.mp4",
}

type Action = "Podcasts" | "PDFs" | "YouTube" | "News" | "Blogs" | "Recipes"
const actions: Action[] = ["Podcasts", "PDFs", "YouTube", "News", "Blogs", "Recipes"]

export default function SummaryExample() {
    const [active, setActive] = useState<string>(actions[0])
    const [isVisible, setIsVisible] = useState(false)
    const [preload, setPreload] = useState({
        Podcasts: "auto",
        PDFs: "none",
        YouTube: "none",
        News: "none",
        Blogs: "none",
        Recipes: "none",
    })
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            setIsVisible(entries[0].isIntersecting)
        })

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
    }, [])

    useEffect(() => {
        actions.forEach((action) => {
            if (action !== active) {
                const video = document.getElementById(`id-${action}`) as HTMLVideoElement
                if (video && isVisible) {
                    video.currentTime = 0
                    video.pause()
                }
            }
        })

        const video = document.getElementById(`id-${active}`) as HTMLVideoElement
        if (video && isVisible) {
            video.currentTime = 0
            video.play()
        }
    }, [active])

    const hoverButton = (action: string) => {
        setPreload((prev) => ({ ...prev, [action]: "auto" }))
    }

    return (
        <Container component="section" sx={styles.section}>
            <SlideUpAnimation>
                <Title component="h2">
                    <Highlight>Save hours every day</Highlight>
                </Title>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.2s">
                <TitleSecondary component="h3" my={1} color="text.secondary">
                    Overwhelmed by Endless Content?
                </TitleSecondary>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.3s">
                <TitleTertiary component="h4" mb={3}>
                    Get the key points of any online content and reclaim your time for what truly
                    matters to you.
                </TitleTertiary>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.4s">
                <Box ref={ref}>
                    <Card sx={{ py: 3, px: { xs: 2, lg: 4 } }}>
                        <ButtonGroup
                            actions={actions}
                            active={active}
                            setActive={setActive}
                            handleHover={hoverButton}
                        />
                        {actions.map((action) => (
                            <Box
                                id={`id-${action}`}
                                preload={preload[action]}
                                key={action}
                                sx={styles.video}
                                display={action === active ? "block" : "none"}
                                component="video"
                                autoPlay={active === action ? true : undefined}
                                muted
                                playsInline
                                loop
                            >
                                <source src={videoUrl[action]} />
                            </Box>
                        ))}
                    </Card>
                </Box>
            </SlideUpAnimation>
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    video: {
        width: { xs: "100%", lg: "80%", aspectRatio: "3652/2160" },
        mx: 4,
        mt: { xs: 2, lg: 4 },
        mb: 2,
        borderRadius: "10px",
        boxShadow: `0 0 20px 0px #000212, 0 0 5px 0px #000212`,
        border: "1px solid #ffffff20",
    },
    section: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        py: { xs: 2, lg: 8 },
    },
}
