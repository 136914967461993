"use client"

import { getTheme } from "@/theme"
import { Box, Container, Divider, SxProps, Theme, useMediaQuery } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { Fragment } from "react"
import { Title } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"

interface MediaLogo {
    src: string
    alt: string
    url: string
    width: number
    height: number
}

const mediaLogos: MediaLogo[] = [
    {
        url: "https://www.benzinga.com/pressreleases/24/01/36742579/revolutionizing-digital-learning-recall-unveils-ai-powered-platform-for-efficient-online-content-su",
        src: "svgs/benzinga.svg",
        alt: "Benzinga News Article",
        width: 180,
        height: 24.5,
    },
    {
        url: "https://markets.businessinsider.com/news/stocks/revolutionizing-digital-learning-recall-unveils-ai-powered-platform-for-efficient-online-content-summarization-1032988897",
        src: "svgs/businessInsider.svg",
        alt: "Business Insider Article",
        width: 160,
        height: 50,
    },
    {
        url: "https://apnews.com/press-release/ein-presswire-newsmatics/artificial-intelligence-data-management-and-storage-social-media-033cab521725ff9dda8d70a73af12bfd",
        src: "svgs/APNews.svg",
        alt: "AP News Article",
        width: 60,
        height: 60,
    },
    {
        url: "https://www.asiaone.com/business/revolutionizing-digital-learning-recall-unveils-ai-powered-platform-efficient-online",
        src: "svgs/asiaone.svg",
        alt: "Asia One News Article",
        width: 180,
        height: 27.65,
    },
]

export default function AsSeenIn() {
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const scale = isMobile ? 0.7 : 1

    return (
        <Container component="section" sx={styles.section}>
            <SlideUpAnimation>
                <Box sx={{ flexGrow: 1, p: 2, pt: 1 }}>
                    <Title component="h2" sx={{ pt: 0 }}>
                        As Seen In
                    </Title>
                    <Box sx={styles.logos}>
                        {mediaLogos.map((logo, index) => (
                            <Fragment key={logo.src}>
                                <Link rel="nofollow" href={logo.url} target="_blank">
                                    <Image
                                        src={logo.src}
                                        alt={logo.alt}
                                        width={logo.width * scale}
                                        height={logo.height * scale}
                                    />
                                </Link>
                                {!isMobile && index !== mediaLogos.length - 1 && (
                                    <Divider
                                        orientation={"vertical"}
                                        sx={{
                                            height: 50,
                                            flex: 0,
                                        }}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </Box>
                </Box>
            </SlideUpAnimation>
        </Container>
    )
}

const theme = getTheme()

const styles: Record<string, SxProps<Theme>> = {
    section: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        my: { xs: 1, lg: 2 },
    },
    title: {
        pb: 1,
        borderRadius: 1,
        mb: { xs: 3, md: 2 },
        color: "text.primary",
        fontWeight: 600,
        textAlign: "center",
    },
    logos: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        flexWrap: "wrap",
        gap: { xs: 2, md: 4 },
        mt: 1,
        pt: 2,
    },
}
